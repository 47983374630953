<template>
    <div>
        <span v-if="label" :class="['label', {'bolder-label': bolderLabel}]">{{ label }}</span>
        <v-text-field
            :autofocus="autofocus"
            :autocomplete="autocomplete"
            :color="color"
            :value="defaultValue"
            @input="onChange"
            @click:clear="model = ''"
            @click="$emit('click')"
            @blur="emitBlur"
            @keydown.enter="emitEnter"
            @keypress="isNumber($event)"
            :placeholder="placeholder"
            :rules="rules"
            :prefix="prefix"
            :clearable="clearable"
            :inputmode="inputMode"
            :hide-details="!showDetails"
            :solo="solo"
            :outlined="outlined"
            :flat="flat"
            :dense="dense"
            :prepend-inner-icon="icon"
            :append-icon="appendIcon"
            @click:append="$emit('click:append')"
            :readonly="readonly"
            :loading="loading"
            :label="inputLabel"
            :class="[{'no-border': noBorder}]"
            :maxlength="maxlength"
        ></v-text-field>
    </div>
</template>

<script>
export default {
    name: "KimsaTextField",
    props: {
        autofocus: {
            type: Boolean,
            default: false
        },
        inputMode: {
            type: String,
            default: 'text'
        },
        clearable: {
            type: Boolean,
            default: false
        },
        prefix: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: '',
        },
        inputLabel: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: 'primary'
        },
        rules: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number],
            default: '',
        },
        elevation: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: '',
        },
        appendIcon: {
            type: String,
            default: '',
        },
        showDetails: {
            type: Boolean,
            default: false,
        },
        autocomplete: {
            type: String,
            default: '',
        },
        disableDots: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        solo: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        forceValue: {
            type: Boolean,
            default: false
        },
        bolderLabel: {
            type: Boolean,
            default: false
        },
        noBorder: {
            type: Boolean,
            default: false
        },
        maxlength: {
            type: [String, Number],
            default: undefined,
        }
    },
    data() {
        return {
            model: '',
        }
    },
    computed: {
        defaultValue() {
            const vm = this
            if (vm.inputMode === 'numeric' && !vm.disableDots) {
                return vm.$options.filters.number(vm.model)
            }
            return vm.model
        }
    },
    methods: {
        onChange(value) {
            const vm = this
            if (!value) return vm.model = ''

            if (vm.inputMode === 'numeric') {
                return vm.model = Number(value.toString().replace(/\./g, ''))
            }

            return vm.model = value
        },
        setDefaults() {
            const vm = this
            if (vm.value || vm.forceValue) {
                vm.model = vm.value
            }
        },
        reset() {
            const vm = this
            vm.model = ''
        },
        isNumber(event) {
            const vm = this
            if (vm.inputMode !== 'numeric') return
            const charCode = event.keyCode
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                event.preventDefault()
            } else {
                return true
            }
        },
        emitBlur() {
            this.$emit('blur')
        },
        emitEnter() {
            this.$emit('keydown:enter')
        }
    },
    mounted() {
        const vm = this;
        vm.setDefaults()
    },
    watch: {
        value() {
            this.setDefaults()
        },
        model(val) {
            this.$emit('change', val)
        }
    }
}
</script>

<style scoped>

.label {
    font-size: 15px;
    color: #333333;
}

.bolder-label {
    font-weight: bold;
}

.no-border {
    border-radius: 0;
}

</style>